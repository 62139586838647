<template>
    <div class="articlesList">
        <div class="articlesList-breadcrumb">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                <el-breadcrumb-item>{{ titleKV[$route.query.aliasTitle] || '郑幼林雕刻艺术馆' }}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="articlesList-search">
            <div class="articlesList-search-content">
                <input v-model="queryObj.title" class="search-input" placeholder="请输入要搜索的内容" />
                <i class="icon el-icon-search" title="点击搜索" @click="searchClickFn"></i>
            </div>
        </div>
        <div class="articlesList-auth" v-if="authList && authList.length > 0">
            <div class="label">
                <span>作者</span>
            </div>
            <div class="contents">
                <div :class="['contents-item', i.checked ? 'contents-checked' : '']" v-for="(i, j) in authList"
                    :key="i.id" @click="authClickFn(i.id)">
                    <span>{{ i.title }}</span>
                </div>
            </div>
        </div>
        <div class="articlesList-type"
            v-if="typesList && typesList.length > 0 && ($route.query.aliasTitle != 'XINXINXUEZI' && $route.query.aliasTitle != 'YISHUJIAJIANJIE')">
            <div class="label">
                <span>类型</span>
            </div>
            <div class="contents">
                <div :class="['contents-item', i.checked ? 'contents-checked' : '']" v-for="(i, j) in typesList"
                    :key="i.id" @click="typeClickFn(i.id)">
                    <span>{{ i.title }}</span>
                </div>
            </div>
        </div>
        <div class="list" v-if="list && list.length > 0" v-loading="loading">
            <div class="list-item">
                <div :class="['list-item-content', $route.query.aliasTitle == 'XINXINXUEZI' ? 'list-item-content2' : '']"
                    v-for="(i, j) in list" :key="i.articlesCode" @click="articleItemClickFn(i.articlesCode)">
                    <div class="coverPhotos">
                        <img :src="i.coverPhotos" />
                    </div>
                    <div class="others">
                        <div class="item">
                            <span v-if="$route.query.aliasTitle == 'XINXINXUEZI'">{{ i.title }}</span>
                            <span v-else-if="$route.query.aliasTitle == 'YISHUJIAJIANJIE'">艺术家：{{ i.title }}</span>
                            <span v-else>标题：{{ i.title }}</span>
                        </div>
                        <div class="item"
                            v-if="$route.query.aliasTitle != 'XINXINXUEZI' && $route.query.aliasTitle != 'YISHUJIAJIANJIE'">
                            <span>类型：{{ i.typesContent }}</span>
                        </div>
                        <div class="item">
                            <span>发布时间：{{ i.releaseTime }}</span>
                        </div>
                        <div class="item">
                            <span>作者：{{ i.articlesDictsAuthorsTitle }}</span>
                        </div>
                        <div class="item">
                            <span>内容简介：{{ i.synopsis }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="pages" v-if="list && list.length > 0">
                <el-pagination background layout="sizes, prev, pager, next, total" @size-change="handleSizeChange"
                    @current-change="handleCurrentChange" :total="total" :page-sizes="[6, 10, 20]"
                    :page-size="queryObj.pageSize" :current-page="queryObj.pageNum">
                </el-pagination>
            </div>
        </div>
        <div class="empty" v-if="list.length == 0">
            <div v-if="showEmpty">
                <el-empty description="未找到数据"></el-empty>
            </div>
        </div>
    </div>
</template>
<script>
import { Message } from 'element-ui'
import { get } from "../../utils/request";
export default {
    data() {
        return {
            titleKV: {
                'XINXINXUEZI': '莘莘学子',
                'YISHUBIJI': '艺术笔记',
                'YISHUJIAJIANJIE': '艺术家简介',
                'ZIXUNXINXI': '展讯信息',
                'ZUOPINXINSHANG': '作品欣赏',
            },
            authList: [],
            typesList: [],
            list: [],
            loading: false,
            queryObj: {
                pageNum: 1,
                pageSize: 6,
                title: '',
                articlesDictsAuthorsId: '',
                types: '',
                synopsis: '',
                orderByColumn: 'release_time',
                isAsc: 'desc'
            },
            total: 1,
            showEmpty: false,
        }
    },
    created() {
        if (this.$route.query.pageNum) this.queryObj.pageNum = parseInt(this.$route.query.pageNum);
        if (this.$route.query.articlesDictsAuthorsId) this.queryObj.articlesDictsAuthorsId = this.$route.query.articlesDictsAuthorsId;
        if (this.$route.query.types) this.queryObj.types = this.$route.query.types;
        if (!this.$route.query.aliasTitle) return Message({ message: '非法请求', type: 'error', duration: 5 * 1000 });
        this.listFn(this.$route.query.aliasTitle);
        this.getArticlesAuths();
        if (this.$route.query.aliasTitle != 'XINXINXUEZI' && this.$route.query.aliasTitle != 'YISHUJIAJIANJIE')
            this.getArticlesTypes(this.$route.query.aliasTitle);
    },
    watch: {
        '$route'(to, from) {
            this.resetFn();
            this.listFn(to.query.aliasTitle);
        },
        'queryObj.title'(val, val2) {
            this.queryObj.synopsis = this.queryObj.title;
        }
    },
    methods: {
        resetFn() {
            this.showEmpty = false;
            this.list = [];
            this.queryObj.pageNum = 1;
            this.total = 1;
        },
        async listFn(aliasTitle) {
            this.loading = true;
            const { data: res } = await get('/public/articles/list/' + aliasTitle, this.queryObj);
            this.loading = false;
            if (res.code == 200) {
                this.list = res.rows;
                this.total = res.total;
                if (this.list.length == 0) this.showEmpty = true;
            }
        },
        async getArticlesAuths() {
            const { data: res } = await get('/articles/auth');
            if (res.code == 200) {
                if (res.data && res.data.length > 0)
                    res.data.forEach((x) => {
                        if (x.id == this.queryObj.articlesDictsAuthorsId) x.checked = true;
                        else x.checked = false;
                    });
                this.authList = res.data;
                if (this.$route.query.aliasTitle == 'XINXINXUEZI' || this.$route.query.aliasTitle == 'YISHUJIAJIANJIE') {
                    this.authList = this.authList.filter((x) => x.title.indexOf('、') == -1);
                }
            }
        },
        async getArticlesTypes(aliasTitle) {
            const { data: res } = await get('/articles/types/' + aliasTitle);
            if (res.code == 200) {
                if (res.data && res.data.length > 0)
                    res.data.forEach((x) => {
                        if (x.id == this.queryObj.types) x.checked = true;
                        else x.checked = false;
                    });
                this.typesList = res.data;
            }
        },
        searchClickFn() {
            this.resetFn();
            this.listFn(this.$route.query.aliasTitle);
        },
        authClickFn(id) {
            const result = this.authList.find((x) => x.id == id);
            if (result) {
                result.checked = !result.checked;
                if (result.checked) this.authList.forEach((x) => {
                    if (x.id != id) x.checked = false;
                });
                this.resetFn();
                this.queryObj.articlesDictsAuthorsId = result.checked ? id : '';
                this.listFn(this.$route.query.aliasTitle);
            }
        },
        typeClickFn(id) {
            const result = this.typesList.find((x) => x.id == id);
            if (result) {
                result.checked = !result.checked;
                if (result.checked) this.typesList.forEach((x) => {
                    if (x.id != id) x.checked = false;
                });
                this.resetFn();
                this.queryObj.types = result.checked ? id : '';
                this.listFn(this.$route.query.aliasTitle);
            }
        },
        articleItemClickFn(articlesCode) {
            this.$router.push({
                path: "/articlesContent",
                query: {
                    articlesCode: articlesCode,
                    aliasTitle: this.$route.query.aliasTitle,
                    pageNum: this.queryObj.pageNum,
                    articlesDictsAuthorsId: this.queryObj.articlesDictsAuthorsId,
                    types: this.queryObj.types,
                },
            });
        },
        handleSizeChange(e) {
            this.queryObj.pageSize = e;
            this.resetFn();
            this.listFn(this.$route.query.aliasTitle);
        },
        handleCurrentChange(e) {
            this.queryObj.pageNum = e;
            this.listFn(this.$route.query.aliasTitle);
        },
    }
}
</script>
<style lang="less" scoped>
.articlesList {
    width: 1000px;
    margin: 0 auto;
    padding: 30px;

    .articlesList-title {
        padding: 30px 0;
        font-size: 30px;
        font-weight: bold;
    }

    .articlesList-breadcrumb {
        margin-bottom: 10px;
    }

    .articlesList-search {
        margin-top: 30px;
        display: flex;
        justify-content: center;
        align-items: center;

        .articlesList-search-content {
            width: 712px;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            border: solid 1px #dfdddd;
            padding: 0 30px;

            .search-input {
                width: 100%;
                height: 40px;
                border: none;
                outline: none;
                font-size: 14px;
            }

            .icon {
                margin-left: 10px;
                font-size: 20px;
            }

            .icon:hover {
                cursor: pointer;
            }
        }
    }

    .articlesList-auth {
        margin-top: 30px;
        display: flex;

        .label {
            width: 50px;
            font-weight: bold;
        }

        .contents {
            display: flex;
            flex-wrap: wrap;
            width: calc(100% - 50px);

            .contents-item {
                padding: 5px 10px;
                border-radius: 4px;
                background-color: #f4f4f5;
                border-color: #e9e9eb;
                color: #909399;
                margin-right: 10px;
                margin-bottom: 10px;
            }

            .contents-checked {
                color: #409eff;
                border: 1px solid #d9ecff;
                background-color: #ecf5ff;
            }

            .contents-item:hover {
                cursor: pointer;
            }
        }
    }

    .articlesList-type {
        display: flex;

        .label {
            width: 50px;
            font-weight: bold;
        }

        .contents {
            width: calc(100% - 50px);
            display: flex;
            flex-wrap: wrap;

            .contents-item {
                padding: 5px 10px;
                border-radius: 4px;
                background-color: #f4f4f5;
                border-color: #e9e9eb;
                color: #909399;
                margin-right: 10px;
                margin-bottom: 10px;
            }

            .contents-checked {
                color: #409eff;
                border: 1px solid #d9ecff;
                background-color: #ecf5ff;
            }

            .contents-item:hover {
                cursor: pointer;
            }
        }
    }

    .list {
        min-height: 394px;

        .list-item {
            display: flex;
            flex-wrap: wrap;

            .list-item-content {
                width: calc(33.33% - 10px);
                max-height: 350px;
                margin-bottom: 10px;
                margin-right: 10px;
                background-color: #efeded;
                margin-bottom: 20px;
                border-top-right-radius: 10px;
                border-top-left-radius: 10px;
                overflow: hidden;

                .coverPhotos {
                    img {
                        width: 100%;
                        height: 212px;
                        object-fit: cover;
                        border-top-right-radius: 10px;
                        border-top-left-radius: 10px;
                    }
                }

                .others {
                    padding: 10px 20px;

                    .item {
                        line-height: 30px;
                    }
                }
            }

            .list-item-content2 {
                width: calc(50% - 10px);
            }

        }

        .list-item:hover {
            cursor: pointer;
        }

        .pages {
            text-align: center;
        }


    }



    .empty {
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 394px;
        font-size: 30px;
        font-weight: 400;
    }
}
</style>